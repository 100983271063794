export enum Role {
  'agentes-buscar',
  'agentes-cadastrar',
  'agentes-editar',
  'agentes-excluir',
  'agentes-listar',
  'empresascoleta-cadastrar',
  'empresascoleta-excluir',
  'empresascoleta-listar',
  'lotes-buscar',
  'lotes-cadastrar',
  'lotes-cadastrar-multiplos',
  'lotes-cadastrar-multiplos-cancelar',
  'lotes-editar',
  'lotes-editar-apto-titulacao',

  'lotes-editar-interesse-regularizacao',
  'lotes-editar-modalidade',
  'lotes-editar-tipo',
  'lotes-editar-tipo-area',
  'lotes-editar-tipo-registro',
  'lotes-excluir',
  'lotes-fracionar',
  'lotes-listar',
  'lotes-unificar',
  'lotes-agentes-atribuir',
  'lotes-agentes-listar',
  'lotes-agentes-remover',
  'anotacoes-cadastrar',
  'anotacoes-excluir',
  'anotacoes-listar',
  'lotes-documento-enviar',
  'lotes-documentos-baixar',
  'lotes-documentos-excluir',
  'lotes-documentos-listar',
  'enderecosinformais-cadastrar',
  'enderecosinformais-excluir',
  'enderecosinformais-listar',
  'etiquetas-cadastrar',
  'etiquetas-excluir',
  'etiquetas-listar',
  'etiquetas-publicas-cadastrar',
  'lotes-etiquetas-atribuir',
  'lotes-etiquetas-excluir',
  'interessados-buscar',
  'interessados-cadastrar',
  'interessados-editar',
  'interessados-excluir',
  'interessados-editar-apto-titulacao',
  'interessados-telefone-cadastrar',
  'interessados-telefone-excluir',
  'interessados-documentos-baixar',
  'interessados-documentos-editar',
  'interessados-documentos-enviar',
  'interessados-documentos-excluir',
  'interessados-documentos-listar',
  'unidades-buscar',
  'unidades-cadastrar',
  'unidades-editar',
  'unidades-excluir',
  'unidades-listar',
  'visitas-buscar',
  'visitas-cadastrar',
  'visitas-editar',
  'visitas-excluir',
  'visitas-listar',
  'visitas-documentos-baixar',
  'visitas-documentos-enviar',
  'visitas-documentos-excluir',
  'visitas-documentos-listar',
  'nucleosurbanos-buscar',
  'nucleosurbanos-cadastrar',
  'nucleosurbanos-editar',
  'nucleosurbanos-excluir',
  'nucleosurbanos-listar',
  'liderancas-cadastrar',
  'liderancas-editar',
  'liderancas-excluir',
  //'liderancas-obter ?',
  'quadras-cadastrar',
  'quadras-listar',
  'usuarios-listar',

  'buscar-titulacao'
}
