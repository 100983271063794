import { Injectable } from '@angular/core';
import { OfflineStorageService } from 'src/app/core/services/offline-storage.service';

@Injectable({
  providedIn: 'root'
})
export class VisitasOfflineService {

  constructor(private _offlineStorageService: OfflineStorageService) { }

  public save(loteId: number, visita: any): Promise<any> {
    let newVisita = {
      key: 'VISITA_LOTE_' + loteId.toString() + '_ID_' + visita.id.toString(),
      data: visita
    };

    return this._offlineStorageService.save(newVisita);
  }

  public delete(key: string): Promise<any> {
    return this._offlineStorageService.delete(key);
  }

  public async get(key: string) {
    const visita = await this._offlineStorageService.get(key);

    return visita == null ? null : { key: key, ...visita };
  }

  public editRequest(requestKey: string, url: string, visita: any) {
    return this._offlineStorageService.saveRequest(requestKey, url, 'POST', visita);
  }

  public async getAll(): Promise<any[]> {
    const visitasOffline = await this._offlineStorageService.getAll('VISITA_');

    return visitasOffline;
  }

  public async getAllFromLote(loteId: number): Promise<any[]> {
    const key_pattern = 'VISITA_LOTE_' + loteId.toString() + '_ID_';

    const visitasFromLote = (await this._offlineStorageService.getAll(key_pattern));
    return visitasFromLote;
  }

  public async getAllVisitaRequests(): Promise<any[]> {
    let visitaRequests: any[] = [];

    (await this._offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/visitas') > -1) {
        visitaRequests.push(request);
      }
    });

    return visitaRequests;
  }

  public async getVisitaRequest(requestKey: string): Promise<any[]> {
    const request = (await this._offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, ...request.data };
  }

  public async getAllVisitaRequestsFromLote(loteId: number): Promise<any[]> {
    let visitasFromRequests: any[] = [];

    (await this._offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/visitas') > -1 && (request.data.loteId === loteId)) {
        visitasFromRequests.push({ key: request.key, ...request.data });
      }
    });

    return visitasFromRequests;
  }

  public saveDocumento(visitaId: string, documento: any): Promise<any[]> {
    let newDocumentoVisita = {
      key: 'DOCUMENTO_VISITA_' + visitaId + '_ID_' + documento.id,
      data: documento
    };

    return this._offlineStorageService.save(newDocumentoVisita);
  }

  public deleteDocumento(documentoKey: string): Promise<any[]> {
    return this._offlineStorageService.delete(documentoKey);
  }

  public getDocumento(documentoKey: string): Promise<any> {
    return this._offlineStorageService.get(documentoKey);
  }

  public async getAllDocumentos(visitaId: number | string): Promise<any[]> {
    const key_pattern = 'DOCUMENTO_VISITA_' + visitaId + '_ID_';
    console.log('EL KEY PATTERN DOCUMENTOS: ', key_pattern);
    const documentosFromVisita = await this._offlineStorageService.getAll(key_pattern);

    return documentosFromVisita;
  }

  public async getDocumentoRequest(requestKey: string): Promise<any> {
    const request = (await this._offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, ...request.data };
  }

  public async getAllDocumentoRequests(visitaKey: string): Promise<any[]> {
    let documentosFromRequests: any[] = [];

    (await this._offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url == null)
        return;

      const indexOfVisitas = request.url.indexOf('/visitas/');
      const indexOfDocumentos = request.url.indexOf('/documentos');

      if (indexOfVisitas == null || indexOfDocumentos == null)
        return;

      const endOfVisitas = '/visitas/'.length;

      const requestVisitaKey = request.url.substring(indexOfVisitas + endOfVisitas, indexOfDocumentos);

      if (indexOfVisitas > -1 && indexOfDocumentos > -1 && (requestVisitaKey === visitaKey)) {
        documentosFromRequests.push({ key: request.key, ...request.data });
      }
    });

    return documentosFromRequests;
  }

  //
  // No momento o agente não possui o id,
  // portanto o nome de usuário está sendo usando como identificador
  //
  public saveAgente(agente: any): Promise<any> {
    const newAgente = {
      //key: 'AGENTE_' + agente.id,
      key: 'AGENTE_' + agente.username,
      data: agente
    };

    return this._offlineStorageService.save(newAgente);
  }

  public async getAgentes(): Promise<any[]> {
    return this._offlineStorageService.getAll('AGENTE_');
  }

}
