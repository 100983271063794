import { Injectable } from '@angular/core';
import { OfflineStorageService } from '../core/services/offline-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LotesOfflineService {

  constructor(private _offlineStorageService: OfflineStorageService) { }

  public save(lote: any): Promise<any> {
    let newLote = {
      key: 'LOTE_' + lote.id,
      data: lote
    };

    return this._offlineStorageService.save(newLote);
  }

  public delete(loteId: number): Promise<any> {
    const key =  'LOTE_' + loteId.toString();
    return this._offlineStorageService.delete(key);
  }

  public get(loteId: number | string) {
    const key =  'LOTE_' + loteId.toString();
    return this._offlineStorageService.get(key);
  }

  public async getAll(): Promise<any[]> {
    return this._offlineStorageService.getAll('LOTE_');
  }

  public async getAllFromNucleoUrbano(nucleoUrbanoId: number): Promise<any[]> {
    let lotesFromNucleoUrbano: any[] = [];
    let lotes = await this._offlineStorageService.getAll('LOTE_');
    lotes.forEach((lote) => {
      if (lote.nucleoUrbanoId === nucleoUrbanoId) {
        lotesFromNucleoUrbano.push(lote);
      }
    });

    return lotesFromNucleoUrbano;
  }

  //
  // DOCUMENTOS
  //

  public async getAllDocumentos(loteKey: string): Promise<any[]> {
    const key_pattern = 'DOCUMENTO_LOTE_' + loteKey.toString() + '_ID_';
    const documentosFromLote = await this._offlineStorageService.getAll(key_pattern);

    return documentosFromLote;
  }

  public saveDocumento(loteKey: string, documento: any): Promise<any[]> {
    let newDocumentoVisita = {
      key: 'DOCUMENTO_LOTE_' + loteKey + '_ID_' + documento.id,
      data: documento
    };

    return this._offlineStorageService.save(newDocumentoVisita);
  }

  public deleteDocumento(documentoKey: string): Promise<any[]> {
    return this._offlineStorageService.delete(documentoKey);
  }

  //
  // ANOTAÇÕES
  //

  public saveAnotacao(loteId: number, anotacao: any): Promise<any> {
    let newAnotacao = {
      key: 'ANOTACAO_LOTE_' + loteId.toString() + '_ID_' + anotacao.id,
      data: anotacao
    };

    return this._offlineStorageService.save(newAnotacao);
  }

  public deleteAnotacao(key: string): Promise<any> {
    return this._offlineStorageService.delete(key);
  }

  public getAnotacao(loteId:number, anotacao: any) {
    const key = 'ANOTACAO_LOTE_' + loteId.toString() + '_ID_' + anotacao.id;
    return this._offlineStorageService.get(key);
  }

  public async getAnotacoesFromLote(loteId: number): Promise<any[]> {
    let anotacoes = await this._offlineStorageService.getAll('ANOTACAO_LOTE');
    anotacoes.forEach((anotacao) => {
      if (anotacao.loteId === loteId) {
        anotacoes.push(anotacao);
      }
    });

    return anotacoes;
  }

  public async getAnotacaoRequestsFromLote(loteId: number): Promise<any[]> {
    let anotacoesFromRequests: any[] = [];

    (await this._offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/anotacoes') > -1 && (request.data.loteId === loteId)) {
        anotacoesFromRequests.push({ key: request.key, ...request.data });
      }
    });

    return anotacoesFromRequests;
  }

  //
  // No momento o agente não possui o id,
  // portanto o nome de usuário está sendo usando como identificador
  //
  public saveAgente(agente: any): Promise<any> {
    const newAgente = {
      //key: 'AGENTE_' + agente.id,
      key: 'AGENTE_' + agente.username,
      data: agente
    };

    return this._offlineStorageService.save(newAgente);
  }

  public async getAgentes(): Promise<any[]> {
    return this._offlineStorageService.getAll('AGENTE_');
  }

}
