import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormularioSugestoesComponent } from './components/formulario-sugestoes/formulario-sugestoes.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PanelModule } from 'primeng/panel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { BlockUIModule } from 'primeng/blockui';
import { ModalUpdateComponent } from './components/modal-update/modal-update.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
  declarations: [
    FormularioSugestoesComponent,
    ModalConfirmationComponent,
    ModalUpdateComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    PanelModule,
    InputTextareaModule,
    ButtonModule,
    BlockUIModule
  ],
  exports: [
    ModalUpdateComponent,
    SafePipe
  ]
})
export class SharedModule { }
