import { Injectable } from '@angular/core';
import { OfflineStorageService } from 'src/app/core/services/offline-storage.service';

@Injectable({
  providedIn: 'root'
})
export class InteressadosOfflineService {

  constructor(private _offlineStorageService: OfflineStorageService) { }

  public save(loteId: number, interessado: any): Promise<any> {
    let newInteressado = {
      key: 'INTERESSADO_LOTE_' + loteId.toString() + '_ID_' + interessado.id.toString(),
      data: interessado
    };

    return this._offlineStorageService.save(newInteressado);
  }

  public delete(key: string): Promise<any> {
    return this._offlineStorageService.delete(key);
  }

  public async get(key: string) {
    const interessado = await this._offlineStorageService.get(key);

    return interessado == null ? null : { key: key, ...interessado };
  }

  public async getAll(): Promise<any[]> {
    const interessadosOffline = await this._offlineStorageService.getAll('INTERESSADO_');

    return interessadosOffline;
  }

  public async getAllFromLote(loteId: number): Promise<any[]> {
    const key_pattern = 'INTERESSADO_LOTE_' + loteId.toString() + '_ID_';

    const interessadosFromLote = (await this._offlineStorageService.getAll(key_pattern));
    return interessadosFromLote;
  }

  public async getAllInteressadosRequests(): Promise<any[]> {
    let interessadosRequests: any[] = [];

    (await this._offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/interessados') > -1) {
        interessadosRequests.push(request);
      }
    });

    return interessadosRequests;
  }

  public async getInteressadoRequest(requestKey: string): Promise<any[]> {
    const request = (await this._offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, ...request.data };
  }

  public async getAllInteressadosRequestsFromLote(loteId: number): Promise<any[]> {
    let interessadosRequests: any[] = [];

    (await this._offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url != null && request.url.indexOf('/interessados') > -1 && (request.data.loteId === loteId)) {
        interessadosRequests.push({ key: request.key, ...request.data });
      }
    });

    return interessadosRequests;
  }

  public saveDocumento(interessadoId: string, documento: any): Promise<any[]> {
    let newDocumentoInteressado = {
      key: 'DOCUMENTO_INTERESSADO_' + interessadoId + '_ID_' + documento.id,
      data: documento
    };

    return this._offlineStorageService.save(newDocumentoInteressado);
  }

  public deleteDocumento(documentoKey: string): Promise<any[]> {
    return this._offlineStorageService.delete(documentoKey);
  }

  public getDocumento(documentoKey: string): Promise<any> {
    return this._offlineStorageService.get(documentoKey);
  }

  public async getAllDocumentos(interessadoId: string): Promise<any[]> {
    const key_pattern = 'DOCUMENTO_INTERESSADO_' + interessadoId.toString() + '_ID_';
    console.log('EL KEY PATTERN DOCUMENTOS: ', key_pattern);
    const documentosFromInteressado = await this._offlineStorageService.getAll(key_pattern);

    return documentosFromInteressado;
  }

  public async getDocumentoRequest(requestKey: string): Promise<any[]> {
    const request = (await this._offlineStorageService.get(requestKey));

    return request == null ? null : { key: requestKey, ...request.data };
  }

  public async getAllDocumentoRequests(interessadoKey: string): Promise<any[]> {
    let documentosFromRequests: any[] = [];

    (await this._offlineStorageService.getAll('REQUEST_')).forEach((request) => {
      if (request.url == null)
        return;

      const indexOfInteressados = request.url.indexOf('/interessados/');
      const indexOfDocumentos = request.url.indexOf('/documentos');

      if (indexOfInteressados == null || indexOfDocumentos == null)
        return;

      const endOfInteressados = '/interessados/'.length;

      const requestInteressadoKey = request.url.substring(indexOfInteressados + endOfInteressados, indexOfDocumentos);

      if (interessadoKey.includes('INTERESSADO')) {
        const parts = interessadoKey.split('_')
        interessadoKey = parts[parts.length - 1];
      }

      if (indexOfInteressados > -1 && indexOfDocumentos > -1 && (requestInteressadoKey === String(interessadoKey))) {
        documentosFromRequests.push({ key: request.key, ...request.data });
      }
    });

    return documentosFromRequests;
  }

}
