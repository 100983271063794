import { Injectable } from '@angular/core';
import { OfflineStorageService } from '../core/services/offline-storage.service';
import { NetworkStatusService } from '../core/services/network-status.service';

@Injectable({
  providedIn: 'root'
})
export class NucleosUrbanosOfflineService {

  constructor(private _offlineStorageService: OfflineStorageService,
              private _networkStatusService: NetworkStatusService) { }

  public save(nucleoUrbano: any): Promise<any> {
    let newNucleoUrbano = {
      key: 'NUI_' + nucleoUrbano.id.toString(),
      data: nucleoUrbano
    };

    return this._offlineStorageService.save(newNucleoUrbano);
  }

  public delete(nucleoUrbano: any): Promise<any> {
    const key = 'NUI_' + nucleoUrbano.id;
    return this._offlineStorageService.delete(key);
  }

  public get(nucleoUrbanoId: number) {
    const key = 'NUI_' + nucleoUrbanoId;
    return this._offlineStorageService.get(key);
  }

  public async getAll(): Promise<any[]> {
    return this._offlineStorageService.getAll('NUI_');
  }

}
